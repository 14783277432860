document.addEventListener('DOMContentLoaded', async () => {
  fetch('/api/protected')
    .then(response => {
      if(!response.ok) {
        window.location.href='/session.html'; // Redirect to login if not authenticated
      } else {
        loadFixtureDays();
      }
    })
    .catch(error => {
      console.error('Error checking session:', error);
      window.location.href='/session.html'; // Redirect on error
    });

  const fixtureDaySelect=document.getElementById('fixture-day-select');
  const predictButton=$('#predict-button');
  const saveButton=$('#save-button');
  const grid=$('#predict-table');

  fixtureDaySelect.innerHTML='<option value="" disabled selected>Select a Fixture Day</option>';
  let currentFixtureDay=null;

  // Load Fixture Days
  function loadFixtureDays() {
    // Fetch fixture days and populate dropdown
    fetch('/api/fixturedays?only_ready=1')
      .then(response => response.json())
      .then(data => {
        if(data.error) {
          console.error("index loadFixtureDays", data.error);
        } else {
          data.forEach(day => {
            const option=document.createElement('option');
            option.value=day.fixtureday_id;
            option.textContent=day.name;
            fixtureDaySelect.appendChild(option);
          });

          // Automatically select the first item and trigger the change event
          if(data.length>0) {
            fixtureDaySelect.selectedIndex=1; // First valid option (0 is the placeholder)
            fixtureDaySelect.dispatchEvent(new Event('change')); // Trigger the change event
          }
        }
      })
      .catch(error => alert('Error fetching fixture days: '+error));
  }

  // Load Predictions for Selected Fixture Day
  function loadPredictions(fixtureday_id) {
    $.get(`/api/predict/get?fixtureday_id=${fixtureday_id}`, (predictions) => {
      if(predictions.length===0) {
        grid.jsGrid('option', 'data', []);
        alert('No predictions available for this Fixture Day.');
        return;
      }

      saveButton.prop('disabled', true);

      // Populate the jsGrid
      const processedData=preprocessData(predictions);
      grid.jsGrid('option', 'data', processedData);
      updatePredictionSummary(processedData);
    });
  }

  // Recalculate Predictions
  function recalculatePredictions() {
    if(!currentFixtureDay) {
      alert("Please select a Fixture Day.");
      return;
    }

    // Call the /api/predict/calculate endpoint
    $.get(`/api/predict/calculate?fixtureday_id=${currentFixtureDay}`, (data) => {
      if(!data||data.length===0) {
        alert("No data available for recalculations.");
        return;
      }

      // Refresh the table with the recalculated data
      const processedData=preprocessData(data);
      grid.jsGrid("option", "data", processedData);
      updatePredictionSummary(processedData);
      saveButton.prop('disabled', false); // Enable Save button
    }).fail((error) => {
      alert("Failed to recalculate predictions: "+error.responseText);
    });
  }

  // Save Updates to the Database
  function savePredictions() {
    const updatedData=grid.jsGrid("option", "data");

    $.ajax({
      url: "/api/predict/save",
      method: "POST",
      contentType: "application/json",
      data: JSON.stringify(updatedData),
      success: () => {
        saveButton.prop('disabled', true);
        const processedData=preprocessData(updatedData);
        updatePredictionSummary(processedData);
      },
      error: (error) => {
        alert("Failed to save predictions: "+error.responseText);
      },
    });
  }

  // Initialize jsGrid
  grid.jsGrid({
    width: "100%",
    height: "650px",
    inserting: false,
    editing: true,
    sorting: true,
    paging: true,
    autoload: false,
    pageSize: 20,
    pageButtonCount: 5,
    fields: [
      { name: "fixtureday_id", title: "Fixture Day ID", type: "number", width: 75, editing: false, visible: false },
      { name: "match_id", title: "Match ID", type: "number", width: 50, editing: false, visible: false },
      { name: "predict_id", title: "Predict ID", type: "number", width: 50, editing: false, visible: false },
      {
        name: "date", title: "Date", type: "text", width: 100, editing: false,
        itemTemplate: function(value) {
          if(!value) return ""; // Handle empty or null values
          const [datePart, timePart]=value.split("T");
          const [year, month, day]=datePart.split("-");
          const [hour, minute]=timePart.split(":");
          return `${day}/${month}/${year} ${hour}:${minute}`; // Format as DD/MM/YYYY HH:MM
        }
      },
      { name: "team1", title: "Home", type: "text", width: 100, editing: false },
      { name: "team2", title: "Away", type: "text", width: 100, editing: false },
      { name: "pred_home", title: "PredHome", type: "text", width: 75, editing: false },
      { name: "pred_away", title: "PredAway", type: "text", width: 75, editing: false },
      { name: "m1", title: "m1", type: "number", width: 50, editing: false },
      { name: "m2", title: "m2", type: "number", width: 50, editing: false },
      { name: "m3", title: "m3", type: "number", width: 50, editing: false },
      { name: "prob_1", title: "1", type: "number", width: 50, editing: false },
      { name: "prob_x", title: "X", type: "number", width: 50, editing: false },
      { name: "prob_2", title: "2", type: "number", width: 50, editing: false },
      { name: "score_home", title: "resHome", type: "number", width: 75 },
      { name: "score_away", title: "resAway", type: "number", width: 75 },
      {
        name: "pred_result", title: "Result", type: "text", width: 50, align: "center",
        itemTemplate: function(value, item) {
          if(!item.m1&&!item.m2&&!item.m3||item.score_home===null||item.score_away===null) {
            return '<div style="color: gray;">NULL</div>';
          }
          return value==="TRUE"? '<div style="color: green;">TRUE</div>':'<div style="color: red;">FALSE</div>';
        },
      },
    ]
  });

  // Attach blur event to handle updates manually
  grid.on('blur', 'input', function() {
    const $input=$(this);
    const rowIndex=$input.closest('tr').index();
    const colIndex=$input.closest('td').index()+3;
    const newValue=$input.val();
    const gridData=grid.data('JSGrid');
    const field=gridData.fields[colIndex];

    if(!field||!field.name) {
      alert('Field not found for column index: '+colIndex);
      return;
    }

    const fieldName=field.name;
    const updatedItem=gridData.data[rowIndex];

    if(updatedItem) {
      updatedItem[fieldName]=newValue;
      gridData.refresh(); // Refresh the grid to reflect changes
      saveButton.prop('disabled', false);
    }
  });

  // Auto-open "main" tab when a FixtureDay is selected
  fixtureDaySelect.addEventListener('change', (event) => {
    const fixturedayId=event.target.value;

    if(!fixturedayId) return; // Exit if no FixtureDay is selected

    currentFixtureDay=fixturedayId;
    loadPredictions(currentFixtureDay);
  });

  function preprocessData(gridData) {
    return gridData.filter(item => {
      return (item&&item.predict_id);
    }).map(item => {
      // Validation Status Logic
      if(!item.m1&&!item.m2&&!item.m3||item.score_home===null||item.score_away===null) {
        item.pred_result="NULL";
      } else {
        const calculatedTendency=
          item.score_home>item.score_away
            ? "1"
            :item.score_home<item.score_away
              ? "2"
              :"X";
        item.pred_result=[item.m1, item.m2, item.m3].includes(calculatedTendency.toString())? "TRUE":"FALSE";
      }

      // Correctness Logic
      item.is_correct=item.pred_result==="TRUE";
      return item;
    });
  }

  function updatePredictionSummary(gridData) {
    const totalGames=gridData.length;
    const correctPredictions=gridData.filter(item => item.is_correct).length;
    const wrongPredictions=totalGames-correctPredictions;
    const correctPercentage=totalGames&&totalGames>0? ((correctPredictions/totalGames)*100).toFixed(2):0;

    $("#prediction-summary").text(
      `Right: ${correctPredictions}, Wrong: ${wrongPredictions}, Accuracy: ${correctPercentage}%`
    );
  }

  // Event: Predict Button Click
  predictButton.on('click', recalculatePredictions);

  // Event: Save Button Click
  saveButton.on('click', savePredictions);
});
